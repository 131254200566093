<template>
  <div
    :class="{
      'mr-md-1 universal-choice-selector database-selector-container': true,
      'w-100 px-3 mb-1': $vuetify.breakpoint.smAndDown,
    }"
  >
    <v-menu transition="slide-y-transition" bottom offset-y>
      <template #activator="{ on, attrs }">
        <v-btn
          block
          class="choice-selector-btn elevation-0 px-2"
          height="30px"
          color="#dee7ee"
          dense
          v-bind="attrs"
          v-on="on"
        >
          <span v-if="items.filter((i) => i.value === value)[0]">
            {{ items.filter((i) => i.value === value)[0].label }}
          </span>
          <span v-else> Select a value </span>
          <v-icon right dark>
            mdi-chevron-down
          </v-icon>
        </v-btn>
      </template>
      <div class="universal-database-selector-menu">
        <div class="px-2 pt-2">
          <v-text-field
            v-model="search"
            prepend-inner-icon="mdi-magnify"
            clearable
            dense
            outlined
            placeholder="Search for value"
            height="30px"
            hide-details
            @click.stop="stub"
          />
        </div>
        <v-list max-height="275">
          <v-list-item-group :value="value" @change="$emit('input', $event)">
            <v-list-item
              v-for="(item, i) in searchedItems"
              :key="i"
              :value="item.value"
              class="px-4 d-flex justify-center align-center white"
            >
              <v-list-item-title>{{ item.label }}</v-list-item-title>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </div>
    </v-menu>
  </div>
</template>

<script>
export default {
  name: "DatabaseSelector",
  props: {
    items: {
      default: () => [],
      type: Array,
    },
    value: {
      default: () => "",
    },
  },
  data() {
    return {
      search: "",
    }
  },
  computed: {
    searchedItems() {
      if (this.search) {
        return this.items.filter(item => item.label.toLocaleLowerCase().includes(this.search.toLocaleLowerCase()))
      }
      return this.items;
    }
  },
  watch: {
    items: {
      handler() {
        this.search = '';
      }
    }
  },
  methods: {
    stub() {},
  }
};
</script>

<style lang="scss" scoped>
.choice-selector-btn {
  text-transform: none;
  font-family: "Open Sans", sans-serif;
  font-size: 13px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #66788e;
}

.universal-database-selector-menu {
  /*For search field*/
  & > div {
    background-color: white;
  }
  .v-text-field__details {
    display: none;
  }
  ::v-deep .v-input__slot {
    min-height: 30px !important;
    padding-left: 6px !important;
  }
  i.v-icon {
    font-size: 18px;
  }
  ::v-deep .v-input__prepend-inner {
    margin-top: 4px !important;
  }
  ::v-deep .v-input__append-inner {
    margin-top: 4px !important;
  }
  ::v-deep .v-input__icon--prepend-inner {
    width: 18px;
  }
  ::v-deep .v-input__append-inner {
    width: 18px;
    button {
      font-size: 18px !important;
    }
  }
  ::v-deep .v-text-field__slot {
    font-family: "Open Sans", sans-serif;
    font-size: 13px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #757885;
  }
  .v-list-item {
    min-height: 32px;
  }
  .v-list-item__title {
    font-size: 13px;
  }
}
</style>
